import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Observable, Subscription, of } from "rxjs";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { MenuItem, MessageService } from "primeng/api";
import { WindowRefService } from "src/app/shared/helpers/window-ref.service";
import { catchError, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ProjectListComponent } from "../../project-list/project-list.component";
import { AppState } from "src/app/shared/services/app-state";
import { OrderService } from "src/app/shared/services/order.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { SmartBuildReportFileDownloadService } from "src/app/shared/services/smartbuild-report-file-download.service";
import { FileSaverService } from "src/app/shared/services/file-saver.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CreateOrderRequest, Order, OrderSequence, PartnerSystem } from "src/app/core/data/models/CreateOrderRequest";

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: "app-roofing-wrx-job-details",
  templateUrl: "./roofing-wrx-job-details.component.html",
  styleUrls: ["./roofing-wrx-job-details.component.scss"],
  providers: [MessageService],
})
export class RoofingWrxJobDetailsComponent implements OnInit {
  @ViewChild("updateFileUpload", { static: false }) updateFileUpload: any;
  @ViewChild("reviseFileUpload", { static: false }) reviseFileUpload: any;
  @ViewChild("projectList", { static: false })
  projectList: ProjectListComponent;
  jobDetailForm: UntypedFormGroup = this.fb.group({
    jobNumber: "",
    comments: "",
    files: null,
    requestData: new Date(),
    status: "",
    statusDate: new Date(),
    employee: "",
    branch: "",
  });
  smartBuildJobEditUrlBase = `${this.appState.configs.SmartBuildPath}Framer/EditWithToken/`;
  @Input() selectedProject: any;
  files: File[] = [];
  sbFiles: any[] = [];
  currentFiles: any[] = [];
  submitButtonDislabed = true;
  errorMessage?: string;
  busy?: Subscription;
  roofingWRXJobsEnabled = false;
  profile: ManufacturerProfile;
  profile$ = this.appState.profile$;
  uploadedFiles: any[] = [];
  items: MenuItem[] = [{ label: "Details", icon: "pi pi-fw pi-list" }];
  activeItem: any;
  comments: any;
  showUpdateJobModal = false;
  showReviseJobModal = false;
  updateJobForm: FormGroup;
  reviseJobForm: FormGroup;
  selectedJobId: number;
  smartBuildData: any;
  wrxData: any;

  constructor(
    private appState: AppState,
    private fb: UntypedFormBuilder,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private orderService: OrderService,
    private smartbuildService: SmartBuildService,
    private windowRef: WindowRefService,
    private smartbuildReportFileDownloadService: SmartBuildReportFileDownloadService,
    private fileSaverService: FileSaverService,
    private messageService: MessageService
  ) {}

  get jobName() {
    return this.jobDetailForm.get("jobName");
  }

  get jobComment() {
    return this.updateJobForm.get("jobComment");
  }

  get jobFiles() {
    return this.updateJobForm.get("jobFiles");
  }

  get jobNotes() {
    return this.reviseJobForm.get("jobNotes");
  }

  ngOnInit(): void {
    this.smartBuildData = this.dialogConfig.data?.smartBuildData;
    this.wrxData = this.dialogConfig.data;
    console.log("Smartbuild Data: ", this.smartBuildData);
    console.log("WRX Data", this.wrxData);
    this.setupForm();
    this.patchForm();
    if (this.dialogConfig.data?.status === "complete") {
      this.items.push({ label: "Download", icon: "pi pi-fw pi-download" });
      this.sbFiles.push({
        label: "SmartBuild Output Report",
        icon: "pi pi-fw pi-file-pdf",
      });
    }
  }

  setupForm() {
    this.updateJobForm = this.fb.group({
      jobComment: "",
    });
    this.reviseJobForm = this.fb.group({
      jobName: "",
      jobNotes: "",
      branch_Id: null,
    });
  }

  patchForm() {
    this.setupComments(this.wrxData?.comments);
    this.setFiles(this.wrxData?.files);

    this.jobDetailForm.patchValue({
      comments: this.wrxData?.comments,
      files: this.wrxData?.files,
    });
    if (this.wrxData?.status === "complete") {
      this.reviseJobForm.patchValue({
        name: this.wrxData?.name,
        comments: "",
        branch_Id: this.wrxData?.branch_id,
      });
    }
  }

  submit() {
    if (this.showUpdateJobModal) {
      const comment = this.updateJobForm?.get("jobComment")?.value;
      const payload = {
        comments: comment,
      };

      if (comment) {
        this.orderService
          .addWRXJobComment(this.wrxData.id, payload)
          .pipe(
            catchError((error: any, caught: Observable<any>) => {
              return this.handleUpdateOrderError(error);
            }),
            tap(() => {
              this.fetchWRXJobInfo(this.wrxData.number, true);
              this.closeModal();
            })
          )
          .subscribe();
      }

      if (this.uploadedFiles.length > 0) {
        this.orderService
          .addWRXJobFiles(this.wrxData.id, this.uploadedFiles)
          .pipe(
            catchError((error: any, caught: Observable<any>) => {
              return this.handleUpdateOrderError(error);
            }),
            tap(() => {
              this.fetchWRXJobInfo(this.wrxData.number, false, true);
              this.closeModal();
            })
          )
          .subscribe();
      }
    } else {
      const order: Order = {
        branch_id: this.reviseJobForm.get("branch_Id")?.value,
        comments: `This is a revision to job number ${this.wrxData.number}: ${
          this.reviseJobForm.get("jobNotes")?.value
        }`,
        name: this.reviseJobForm.get("jobName")?.value,
        isRevision: true,
      };

      const request: CreateOrderRequest = {
        jobName: this.jobName.getRawValue(),
        jobComments: this.jobNotes.getRawValue(),
        orderSequence: OrderSequence.SmartBuildToRoofingWRXJob,
        roofData: {
          orderModel: JSON.stringify(order),
          orderModelPartnerSystem: PartnerSystem.RoofingWRX,
        },
      };

      this.orderService
        .processNewOrder(request, this.files)
        .pipe(
          catchError((error: any, _caught: Observable<any>) => {
            return this.handleUpdateOrderError(error);
          }),
          tap(() => {
            this.clearFiles();
            this.showUpdateJobModal = false;
            this.close();
          })
        )
        .subscribe();
    }
  }

  fetchWRXJobInfo(id: number, updateComments = false, updateFiles = false) {
    this.orderService
      .getRoofingWRXJobInfo(this.wrxData.number)
      .pipe(
        catchError((error: any, caught: Observable<any>) => {
          return this.handleUpdateOrderError(error);
        }),
        tap((x: any) => {
          this.wrxData = x ?? [];
          if (updateComments) {
            this.setupComments(x.comments);
            this.jobComment.setValue("");
          }
          if (updateFiles) {
            this.setFiles(x.files);
            this.clearFiles();
          }
        }),
        tap(() => {
          this.patchForm();
        })
      )
      .subscribe();
  }

  public downloadSmartBuildReportFile(): void {
    this.smartbuildReportFileDownloadService
      .downloadSmartBuildReportFile(this.wrxData.number)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let downloadErrorMessage: string;
          downloadErrorMessage = this.setSmartBuildOutputReportErrorMessage(error.status);
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: downloadErrorMessage,
          });
          return of(null);
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Report has been downloaded.",
          });
        })
      )
      .subscribe();
  }

  downloadWRXFile(file: any): void {
    this.orderService
      .downloadWRXFile(this.wrxData.id, file.name)
      .pipe(
        catchError((error: any, caught: Observable<any>) => {
          return this.handleImageFileDownloadError(error);
        }),
        tap((response: any) => {
          this.handleDownloadImageFile(file.name, JSON.parse(response));
        })
      )
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  setFiles(files: any) {
    this.currentFiles = this.wrxData?.files;
  }

  setupComments(comments: any) {
    this.comments = comments;

    if (this.comments) {
      for (let comment of this.comments) {
        if (this.wrxData.contact_id === comment.contact_id) {
          comment.isExternalMessage = false;
        } else {
          comment.isExternalMessage = true;
        }
      }
    }
  }

  closeModal() {
    if (this.showReviseJobModal) {
      this.showReviseJobModal = false;
      this.reviseJobForm.reset();
      this.reviseFileUpload.clear();
    } else {
      this.showUpdateJobModal = false;
      this.updateJobForm.reset();
      this.updateFileUpload.clear();
    }
  }

  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      if (this.showReviseJobModal) {
        this.uploadedFiles.push(file);
      } else {
        if (!this.currentFiles?.some((item) => item.name == file.name)) {
          this.uploadedFiles.push(file);
        } else {
          this.clearFiles();
          this.messageService.add({
            severity: "warn",
            summary: "Warn",
            detail: "File with the same name already exist.  Please change name or upload a new file",
          });
        }
      }
    }
  }

  removeFile(event: any, isUpdate: boolean = false, isRevise: boolean = false) {
    let selectedIndex: number;

    if (isUpdate) {
      selectedIndex = this.updateFileUpload.files.indexOf(event.file);
    } else {
      selectedIndex = this.reviseFileUpload.files.indexOf(event.file);
    }
    this.uploadedFiles.splice(selectedIndex);
  }

  clearFiles() {
    this.reviseFileUpload?.clear();
    this.updateFileUpload?.clear();
    this.uploadedFiles = [];
  }

  base64toBlob(decodedBase64String: string, contentType = "", sliceSize = 512) {
    const byteArrays = [];
    for (let offset = 0; offset < decodedBase64String.length; offset += sliceSize) {
      const slice = decodedBase64String.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  openJobInSB() {
    this.openSB(this.wrxData?.smartBuildData);
  }

  public openSB(params: any): void {
    this.smartbuildService.getSmartBuildToken().subscribe((token) => {
      this.windowRef.nativeWindow.open(`${this.smartBuildJobEditUrlBase}${params.jobId}?token=${token}`);
    });
  }

  private setSmartBuildOutputReportErrorMessage(status: number) {
    var statusMessage: string;
    switch (status) {
      case 400:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.NoDefaultOutputMessage;
        break;
      case 403:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.UnauthorizedMessage;
        break;
      case 500:
        statusMessage = AppConstants.SmartBuildOutputReportErrors.ServerError;
        break;
      default:
        statusMessage = AppConstants.RoofingPassportCommunicationError;
    }
    return statusMessage;
  }

  private handleDownloadImageFile(fileName: string, response: any): void {
    var decodedFile = atob(response);
    var blob = this.base64toBlob(decodedFile);
    this.fileSaverService.saveFile(blob, `${fileName}`);
  }

  private handleImageFileDownloadError(error: HttpErrorResponse) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: "File unavailable, try again later.",
    });
    return of({});
  }

  private handleUpdateOrderError(error: HttpErrorResponse) {
    this.errorMessage = error.error;
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: this.errorMessage,
    });
    return of({});
  }
}
