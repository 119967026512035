<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<div class="job-form" fxLayout="row" fxLayoutGap="20px">
  <div fxFlex="1 0 30%" class="job-form-info">
    <p>
      <img class="project-icon" src="assets/img/logo_roofingwrx.png" width="25%" height="25%" />
    </p>
    <br />
    <p class="job-form-title mb-2">
      Company Name:
      <span class="manufacturer-name">{{ (profile$ | async)?.manufacturer.name }}</span>
    </p>
    <p class="job-form-title mb-2">
      Job Name: <span class="manufacturer-name">{{ wrxData.name }}</span>
    </p>
    <p class="job-form-title mb-2">
      Job Number:
      <span class="manufacturer-name">
        {{ smartBuildData?.jobId }}
      </span>
    </p>
    <p class="job-form-title mb-2">
      Request Date:
      <span class="manufacturer-name">{{ wrxData.request_date | date: "MM/dd/yyyy" }}</span>
    </p>
    <p class="job-form-title mb-2">
      Status: <span class="manufacturer-name">{{ wrxData.status }}</span>
    </p>
    <p class="job-form-title mb-2">
      Status Date:
      <span class="manufacturer-name">{{ wrxData.status_date | date: "MM/dd/yyyy" }}</span>
    </p>
  </div>

  <div fxFlex="1 1 70%" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
    <p-panel style="height: 100%">
      <ng-template pTemplate="body">
        <div fxFlex="0 0 100%" class="form-field">
          <div fxLayoutAlign="end centebynr">
            <p-button
              *ngIf="wrxData.smartBuildData?.canOpen === true"
              type="button"
              (click)="openJobInSB()"
              label="Open Job in Smartbuild"
              styleClass="p-button-raised p-button-text p-button-primary"
            ></p-button
            >&nbsp;
            <p-button
              *ngIf="wrxData.status === 'complete'"
              type="button"
              (click)="showReviseJobModal = true"
              label="Revise"
              styleClass="p-button-raised p-button-text p-button-primary"
            ></p-button>
            <p-button
              *ngIf="wrxData.status !== 'complete'"
              type="button"
              (click)="showUpdateJobModal = true"
              label="Update"
              styleClass="p-button-raised p-button-text p-button-primary"
            ></p-button>
            <p-button
              type="button"
              (click)="close()"
              label="Close"
              styleClass="p-button-raised p-button-text p-button-danger"
            ></p-button
            >&nbsp;
          </div>
          <p-divider></p-divider>
          <p-tabView>
            <p-tabPanel header="Details">
              <p-panel header="Comments">
                <ng-template pTemplate="body">
                  <div *ngIf="comments?.length; else noComment" class="chat">
                    <div *ngFor="let comment of comments; let i = index">
                      <div *ngIf="comment.isExternalMessage === true" class="yours messages">
                        <div class="message last">
                          <b>{{ comment.contact_name }}:&nbsp;</b><span>{{ comment.text }}</span>
                        </div>
                      </div>
                      <div *ngIf="comment.isExternalMessage === false" class="mine messages">
                        <div class="message">
                          <b>Me:&nbsp;</b><span>{{ comment.text }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #noComment> There are currently no comments for this job. </ng-template>
                </ng-template>
              </p-panel>
              <br />
              <p-panel header="Uploaded Files">
                <div *ngIf="currentFiles?.length > 0">
                  <p-table [value]="currentFiles">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>File Name</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-file>
                      <tr>
                        <td>
                          <a href="javascript:void()" (click)="downloadWRXFile(file)">{{ file.name }}</a>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <div *ngIf="currentFiles?.length === 0 || !currentFiles">
                  There are currently no files for this job.
                </div>
              </p-panel>
            </p-tabPanel>
            <p-tabPanel *ngIf="wrxData?.status === 'complete'" header="Download">
              <p-panel header="Files">
                <p-panel header="SmartBuild">
                  <div *ngIf="sbFiles?.length > 0">
                    <p-table [value]="sbFiles">
                      <ng-template pTemplate="header">
                        <tr>
                          <th>File Name</th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-file>
                        <tr>
                          <td>{{ file.label }}</td>
                          <td>
                            <button
                              pButton
                              pRipple
                              icon="pi pi-download "
                              class="p-button-sm p-button-text p-button-primary mr-2"
                              (click)="downloadSmartBuildReportFile()"
                            ></button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                  <div *ngIf="sbFiles?.length === 0 || !sbFiles">There are currently no files for this job.</div>
                </p-panel>
              </p-panel>
            </p-tabPanel>
          </p-tabView>
        </div>
      </ng-template>
      <ng-template pTemplate="footer"> </ng-template>
    </p-panel>
  </div>
  <p-dialog
    header="Update"
    [(visible)]="showUpdateJobModal"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <form novalidate [formGroup]="updateJobForm" (ngSubmit)="submit()">
      <p-panel header="Add File(s)">
        <ul class="valid-documents">
          <li>Click Choose to select a file.<br /><span>(You can also drag and drop a file.)</span></li>
        </ul>
        <p-fileUpload
          #updateFileUpload
          (onSelect)="onUpload($event)"
          (onClear)="clearFiles()"
          (onRemove)="removeFile($event)"
          [multiple]="true"
          maxFileSize="200000000"
          [showUploadButton]="false"
        >
        </p-fileUpload>
      </p-panel>
      <br />
      <p-panel header="Add Comment(s)">
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" formControlName="jobComment"></textarea>
      </p-panel>
      <p-divider></p-divider>
      <div fxLayoutAlign="end centebynr">
        <p-button
          [disabled]="(!updateJobForm.dirty || !jobComment?.value.trim()) && uploadedFiles.length === 0"
          type="submit"
          label="Apply Changes"
          styleClass="p-button-raised p-button-primary"
        ></p-button
        >&nbsp;
        <p-button
          type="button"
          (click)="closeModal()"
          label="Close"
          styleClass="p-button-raised p-button-text p-button-danger"
        ></p-button>
      </div>
    </form>
  </p-dialog>
  <p-dialog
    header="Revise"
    [(visible)]="showReviseJobModal"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <form novalidate [formGroup]="reviseJobForm" (ngSubmit)="submit()">
      <label><b>Job Name</b></label>
      <input style="width: 85%" pInputText type="text" formControlName="jobName" class="w-full" />
      <br />
      <ul class="valid-documents">
        <li>
          If you have changes to your drawings for this job you want made into an updated XML, submit the new drawings
          and any new comments here. Roofing WRX will provide a quote to you to make the changes, and you can confirm
          whether you want to proceed or not.
        </li>
      </ul>
      <p-panel header="Add File(s)">
        <ul class="valid-documents">
          <li>Click Choose to select a file.<br /><span>(You can also drag and drop a file.)</span></li>
          <li *ngIf="!showReviseJobModal">
            After you select a file, click Upload and file(s) will uploaded to your job.
          </li>
        </ul>
        <p-fileUpload
          #reviseFileUploader
          (onSelect)="onUpload($event)"
          (onClear)="clearFiles()"
          (onRemove)="removeFile($event)"
          [multiple]="true"
          maxFileSize="200000000"
          [showUploadButton]="false"
        >
        </p-fileUpload>
      </p-panel>
      <br />
      <p-panel header="Add Comment(s)">
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" formControlName="jobNotes"></textarea>
      </p-panel>
      <p-divider></p-divider>
      <div fxLayoutAlign="end centebynr">
        <p-button
          [disabled]="(!reviseJobForm.dirty || !jobNotes?.value.trim()) && uploadedFiles.length === 0"
          type="submit"
          label="Request Revision"
          styleClass="p-button-raised p-button-primary"
        ></p-button
        >&nbsp;
        <p-button
          type="button"
          (click)="closeModal()"
          label="Close"
          styleClass="p-button-raised p-button-text p-button-danger"
        ></p-button>
      </div>
    </form>
  </p-dialog>
</div>
