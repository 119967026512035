import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-profile-select",
  templateUrl: "./profile-select.component.html",
})
export class ProfileSelectComponent implements OnInit {
  constructor(
    private appState: AppState,
    private profileService: ProfileService,
    private router: Router
  ) {}

  profiles: ManufacturerProfile[] = [];
  busy: Subscription;

  ngOnInit(): void {
    this.appState.appDataOnce$.subscribe(
      (data: any) =>
        (this.profiles = data.profiles.filter(
          (x) =>
            (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
            x.nonWRXRegistrationCompleted
        ))
    );
  }

  selectProfile(profile: ManufacturerProfile) {
    this.busy = this.profileService.chooseProfile(profile.manufacturer.id).subscribe(() => {
      this.router.navigate(["profile"]);
    });
  }
}
