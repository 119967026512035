import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";
import { WindowRefService } from "../../shared/helpers/window-ref.service";
import { takeUntil } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { MenuItem, MessageService } from "primeng/api";
import { Menu } from "primeng/menu";
import { FaqDialogComponent } from "src/app/features/faq-dialog/faq-dialog.component";
import { AdminService } from "src/app/shared/services/admin.service";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { UserInfoService } from "src/app/shared/services/user-info.service";
import { ManufacturerProfile } from "../data/models/AppInitializationData";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmService } from "../confirm/confirm.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [MessageService, DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() logout: EventEmitter<boolean> = new EventEmitter();
  @Output() unlinkAll: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(MatExpansionPanel) accountMenu: MatExpansionPanel;
  @ViewChild(MatButtonToggleGroup) profileList: MatButtonToggleGroup;

  @Input() claims: any;
  ref: DynamicDialogRef | undefined;
  menuItems: MenuItem[];
  configs = this.appState.configs;
  staticImagesBasePath = this.appState.configs.StaticImagesBasePath.endsWith("/")
    ? this.appState.configs.StaticImagesBasePath
    : this.appState.configs.StaticImagesBasePath.padEnd(this.appState.configs.StaticImagesBasePath.length + 1, "/");
  siteLogoUrl = `${this.staticImagesBasePath}roofing-passport-site-logo.png?${this.appState.imageRefreshToken}`;
  busy: Subscription;

  profile$ = this.appState.profile$;
  profile: ManufacturerProfile = null;
  profiles: ManufacturerProfile[] = [];
  selectedProfile: number = this.appState.profileId;
  errorMessage: string;
  isAdmin: boolean = false;

  private destroyed$ = new ReplaySubject<boolean>(1);

  constructor(
    private appState: AppState,
    private smartBuildService: SmartBuildService,
    private windwService: WindowRefService,
    private profileService: ProfileService,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserInfoService,
    private adminService: AdminService,
    public spinner: SpinnerService,
    private confirmService: ConfirmService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    // get the initial list of profiles
    this.appState.appDataOnce$.subscribe((data: any) => {
      this.profiles =
        data.profiles.filter(
          (x: ManufacturerProfile) =>
            (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
            x.nonWRXRegistrationCompleted
        ) ?? [];
      if (data) {
        this.setMenuItems();
      }
    });

    // listen for current profile changes and select the correct item in list
    this.appState.profile$.pipe(takeUntil(this.destroyed$)).subscribe((profile) => {
      this.selectedProfile = profile.manufacturer.id;
      this.isAdmin = this.appState.isAdmin;
      this.profile = profile;
    });

    // subscribe to route changes
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((route) => {
      // Set list of manufacturers in menu when navigating from intial manufacturer selection.
      if (route instanceof NavigationEnd && route.url === "/jobs") {
        this.setMenuItems();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setMenuItems() {
    this.menuItems = [
      {
        label: "My Passport",
        escape: false,
        command: () => {
          this.accountHandler();
        },
      },
      {
        label: "Elevate Your Products",
        escape: false,
        command: () => {
          this.elevateYourProducts();
        },
      },
      {
        label: "User Guide",
        escape: false,
        command: () => {
          this.downloadUserGuide(false);
        },
      },
      {
        label: "FAQ",
        escape: false,
        command: () => {
          this.faqHandler();
        },
      },
      {
        separator: true,
      },
      {
        label: "Unlink Accounts",
        escape: false,
        command: () => {
          this.logoutAllHandler();
        },
      },
      {
        label: "Logout",
        escape: false,
        command: () => {
          this.logoutHandler();
        },
      },
      {
        separator: true,
      },
    ];

    if (this.selectedProfile) {
      let selectedProfileName = this.profiles.find((x) => x.manufacturer?.id === this.selectedProfile)?.manufacturer
        .name;

      if (this.appState.isAdmin) {
        this.menuItems.splice(3, 0, {
          label: "Admin User Guide",
          escape: false,
          command: () => {
            this.downloadUserGuide(true);
          },
        });
      }

      if (!this.profiles.length) {
        return;
      }

      if (this.profiles.length === 1) {
        this.menuItems.push({
          label: selectedProfileName,
          escape: false,
        });
      } else {
        let manufacturerProfiles = [];

        for (let profile of this.profiles) {
          if (profile.manufacturer.id === this.selectedProfile) {
            manufacturerProfiles.push({
              label: profile.manufacturer.name,
              icon: "pi pi-check",
              escape: false,
            });
          } else {
            manufacturerProfiles.push({
              label: profile.manufacturer.name,
              escape: false,
              command: () => {
                this.chooseProfile(profile);
              },
            });
          }
        }

        this.menuItems.push({
          label: "Manufacturers",
          escape: false,
          items: manufacturerProfiles,
        });
      }
    }
  }

  faqHandler() {
    this.ref = this.dialogService.open(FaqDialogComponent, {
      showHeader: true,
      width: "1200px",
      modal: true,
    });
  }

  downloadUserGuide(isAdmin: boolean): void {
    const downloadUserGuide = isAdmin
      ? this.adminService.downloadAdminUserGuide()
      : this.userService.downloadUserGuide();

    downloadUserGuide.subscribe(
      () => {
        this.showSuccessToast("User Guide has been downloaded.");
      },
      (error) => {
        this.handleDownloadError(error);
      }
    );
  }

  chooseProfile(profile: ManufacturerProfile) {
    this.selectedProfile = profile.manufacturer.id;
    this.setMenuItems();
    if (this.appState.profileId != profile.manufacturer.id) {
      this.busy = this.profileService.chooseProfile(profile.manufacturer.id).subscribe((_) => {
        if (this.appState.needsSetupFlow) {
          this.router.navigate(["profile"]);
        }
        this.closeMenu();
      });
    }
  }

  public closeMenu() {
    this.accountMenu?.close();
  }

  public openMenu(menu: Menu, event: any) {
    menu?.toggle(event);
  }

  accountHandler() {
    this.windwService.nativeWindow.open(this.appState.configs.AccountUrl);
  }

  logoutHandler() {
    this.confirmService.confirm(
      "Logout",
      "Are you sure you want to logout?",
      "pi pi-question-circle",
      () => {
        this.logout.emit(true);
      },
      () => {
        this.confirmService.close();
      }
    );
  }

  logoutAllHandler() {
    this.unlinkAll.emit(true);
  }

  elevateYourProducts() {
    this.windwService.nativeWindow.open(this.appState.configs.ElevateYourProducts);
  }

  private showSuccessToast(message: string): void {
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: message,
    });
  }

  private handleDownloadError(error: any): void {
    if (typeof error === "object") {
      this.errorMessage = error.error ?? AppConstants.RoofingPassportCommunicationError;
    } else {
      this.errorMessage = error;
    }

    this.showErrorToast(this.errorMessage);
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }
}
