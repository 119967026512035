import { AuthContext } from "src/app/shared/services/authentication.service";

export enum UserRole {
  Normal = "Normal",
  Admin = "Admin",
  RPAdmin = "RPAdmin",
  Reporting = "Reporting",
}

export enum AdminContext {
  Internal = "Internal",
  SmartBuild = "SmartBuild",
  EagleView = "EagleView",
  RoofingWRX = "RoofingWRX",
}

export enum ProfileStatus {
  Invalid = "Invalid",
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Revoked = "Revoked",
  Inactive = "Inactive",
}

export enum AuthStatus {
  None = "None",
  Valid = "Valid",
  Expired = "Expired",
  Error = "Error",
}

export enum ProfilePayee {
  Manufacturer = "Manufacturer",
  Company = "Company",
}

export interface AppInitializationData {
  license: LicenseAgreementStatus;
  imageRefreshToken: string;
  user: UserData;
  templateData?: UiTemplateData[];
  faqEntries?: FAQEntry[];
  profiles?: ManufacturerProfile[];
  manufacturers?: Manufacturer[];
}

export interface UserData {
  isActive: boolean;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  role: UserRole;
  adminContext: AdminContext;
}

export interface UiTemplateData {
  key: string;
  url: string;
  urlText: string;
  toolTip: string;
  order: number;
}

export interface FAQEntry {
  question: string;
  answer: string;
}

export interface Manufacturer {
  id: number;
  name: string;
  termsUrl: string;
  roofingWRXEnabled?: boolean;
  roofingWRXJobsEnabled?: boolean;
  roofingWRXCompanyId?: number;
  xmlDisabledMessage?: string;
  smartBuildDistributorId?: number;
}

export interface ManufacturerProfile {
  status: ProfileStatus;
  role: UserRole;
  payee: ProfilePayee;
  manufacturer: Manufacturer;
  licenseAgreement: LicenseAgreementStatus;
  authorizations?: ThirdPartyAuth[];
  roofingWRXBranches?: RoofingWRXBranch[];
  roofingWRXEnabled?: boolean;
  roofingWRXJobsEnabled?: boolean;
  allRegistrationCompleted?: boolean;
  nonWRXRegistrationCompleted?: boolean;
  isDefaultProfile?: boolean;
  skipRegistrationStatus?: boolean;
}

export interface ThirdPartyAuth {
  context: AuthContext;
  status: AuthStatus;
  registrationCompleted?: string;
  claims?: any;
}

export interface LicenseAgreementStatus {
  accepted: boolean;
  lastAcceptedDateTime: Date;
}

export interface RoofingWRXBranch {
  id?: number;
  company_id?: number;
  name?: string;
  billing_status?: number;
}
