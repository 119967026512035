import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { AdminTrainingStep, PartnerRegistration, TrainingStep } from "src/app/core/data/models/PartnerRegistration";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import { AuthContext } from "src/app/shared/services/authentication.service";
import { Checkbox } from "primeng/checkbox";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { MessageService } from "primeng/api";

@Component({
  selector: "admin-task-user-registration-details",
  templateUrl: "./admin-task-user-registration-details.component.html",
  styleUrls: ["./admin-task-user-registration-details.component.scss"],
  providers: [MessageService],
})
export class AdminTaskUserRegistrationDetailsComponent implements OnInit {
  taskDetails: AdminTask;
  registrationForm = this.fb.group({
    username: [null],
    password: [null],
  });

  isExistingAccount = new UntypedFormControl(false);
  evCreditCardOnFile = new UntypedFormControl(false);
  wrxCreditCardOnFile = new UntypedFormControl(false);

  busy: Subscription;
  showPassword: boolean = false;
  isCompletingTask: boolean = false;

  constructor(
    public appState: AppState,
    public profileService: ProfileService,
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public spinner: SpinnerService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  get contextName() {
    return AppConstants.ThirdPartyServiceName[this.taskDetails.partnerSystem];
  }

  get username() {
    return this.registrationForm.get("username");
  }

  get password() {
    return this.registrationForm.get("password");
  }

  ngOnInit(): void {
    this.taskDetails = this.dialogConfig.data;

    this.isExistingAccount.valueChanges.subscribe((isExisting) => {
      if (isExisting) {
        this.password.clearValidators();
        this.password.setValue("");
      } else {
        this.password.setValidators(Validators.required);
      }
      this.password.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      });
    });

    this.wrxCreditCardOnFile.valueChanges.subscribe();
    this.evCreditCardOnFile.valueChanges.subscribe();

    if (this.taskDetails?.lastCompletedTrainingStepDate) {
      let date = new Date(this.taskDetails.lastCompletedTrainingStepDate);
      this.taskDetails.lastCompletedTrainingStepDate = date.toLocaleDateString() + " " + date.toLocaleTimeString();
    }
    if (this.taskDetails?.lastCompletedAdminTrainingStepDate) {
      let date = new Date(this.taskDetails.lastCompletedAdminTrainingStepDate);
      this.taskDetails.lastCompletedAdminTrainingStepDate = date.toLocaleDateString() + " " + date.toLocaleTimeString();
    }
  }

  onChecked(checkboxType: Checkbox) {}

  close(taskCompleted: boolean) {
    this.dialogRef.close(taskCompleted);
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }

  submit() {
    if (this.username.value != null && this.password.value != null) {
      let data: PartnerRegistration = {
        userId: this.taskDetails.inviteeUserId?.toString(),
        context: this.taskDetails.partnerSystem,
        manufacturerId: this.taskDetails.inviteeCompanyId?.toString(),
        username: this.username.value,
        password: this.password.value,
        wrxCreditCardOnFile: this.wrxCreditCardOnFile.value,
        evCreditCardOnFile: this.evCreditCardOnFile.value,
        task: this.taskDetails,
      };

      this.profileService.completeRegistration(data, this.taskDetails.id).subscribe({
        next: () => {
          this.isCompletingTask = false;
          this.close(true);
        },
        error: (err) => {
          this.showErrorToast(err.message);
          this.isCompletingTask = false;
        },
      });
    }
  }
}
