<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<div class="job-form" fxLayout="row" fxLayoutGap="20px">
  <div fxFlex="1 0 23%" class="job-form-info">
    <h5 class="job-form-title mb-2">
      New Job From Files<br /><span class="manufacturer-name">{{ (profile$ | async)?.manufacturer.name }}</span>
    </h5>
    <p>
      Begin a new job using your linked Roofing WRX account by uploading plans and documents related to your project.
    </p>
    <br />
    <p>Please include as many helpful documents as possible so your order can be processed in a timely manner.</p>
    <br />
    <p>
      If you would like Roofing WRX to work on an address where EagleView did not have sufficient coverage, you can
      write the address in the comments section and request for Roofing WRX poor coverage support. Please also load a
      pdf (blank or one that doesn't matter / not confidential) as the system requires an attachment.
    </p>
    <p><b>Valid documents may include:</b></p>
    <ul class="valid-documents">
      <li>Dimensioned Sketches<br /><span>(Slope or Ridge to Eave measurements included)</span></li>
      <li>Architectural Plans<br /><span>(Roof Plan, Dimensioned floor Plan and Elevations at a minimum)</span></li>
      <li>Aerial Photos<br /><span>(with a scale and all slopes included)</span></li>
      <li>Satellite Imagery<br /><span>(with a scale and all slopes included)</span></li>
    </ul>
  </div>
  <div fxFlex="1 1 47%" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" [formGroup]="newJobForm">
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Job Name<sup>*</sup></div>
        <input
          pInputText
          class="p-inputtext-sm"
          type="text"
          class="width-100-pct"
          formControlName="name"
          placeholder=""
          maxlength="29"
        />
        <div class="form-error">
          <span *ngIf="jobName.invalid && jobName.value.length == 0 && (jobName.touched || jobName.dirty)"
            >A job name is required</span
          >
          <span *ngIf="jobName.invalid && jobName.value.length > 0 && (jobName.touched || jobName.dirty)"
            >Job name cannot be longer than 29 characters.</span
          >
        </div>
      </div>
      <div fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Job Type<sup>*</sup></div>
        <select formControlName="jobType" (selectionChange)="submitButtonDisabled = true">
          <option value="">Please select type of job.</option>
          <option *ngFor="let jobType of jobTypes" value="{{ jobType.name }}">
            {{ jobType.name }}
          </option>
        </select>
        <div class="form-error">
          <span *ngIf="jobType.invalid && (jobType.touched || jobType.dirty)">Job Type is required</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <div *ngIf="branches.length > 1" fxFlex="1 1 50%" class="form-field">
        <div class="form-label">Billing Branch<sup>*</sup></div>
        <select formControlName="branch_Id">
          <option value="">-- Select Branch --</option>
          <option *ngFor="let branch of branches; let i = index" value="{{ branch.id }}">
            {{ branch.name }} ({{ branch.company_id }})
          </option>
        </select>
        <div class="form-error">
          <span *ngIf="branchId.value.length == 0 && (branchId.touched || branchId.dirty)"
            >A branch selection is required</span
          >
        </div>
      </div>
      <div class="flex align-items-center">
        <p-checkbox
          #wrxOnly
          name="roofingWRXOnly"
          value="{{ roofingWRXOnly }}"
          (onChange)="onChangeRoofingWRXOnly()"
        ></p-checkbox>
        <label class="ml-2">RoofingWRX Only</label>
      </div>
    </div>

    <div fxFlex="0 0 auto" class="form-field">
      <div class="form-label">Pricing Model</div>
      <div class="flex align-items-center mb-2">
        <p-radioButton
          name="pricingmodel"
          value="Single"
          inputId="Single"
          label="Single Structure"
          formControlName="pricingmodel"
          labelStyleClass="mb-0"
        ></p-radioButton>
      </div>
      <div class="flex align-items-center">
        <p-radioButton
          name="pricingmodel"
          value="Multi"
          inputId="Multi"
          label="Multiple Structure"
          formControlName="pricingmodel"
          labelStyleClass="mb-0"
        ></p-radioButton>
      </div>
    </div>

    <div *ngIf="roofingWRXOnly.value">
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Coverage Width (in inches)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="0"
            formControlName="coverageWidth"
          />
          <div class="form-error">
            <span *ngIf="coverageWidth.value == null && (coverageWidth.touched || coverageWidth.dirty)"
              >Coverage width is required</span
            >
          </div>
        </div>
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Ridge Offset (in inches)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="0"
            min="-12"
            max="12"
            formControlName="ridgeOffset"
          />
          <div class="form-error">
            <span *ngIf="ridgeOffset.invalid && (ridgeOffset.touched || ridgeOffset.dirty)"
              >Value must be between -12 and 12</span
            >
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Hip Offset (in inches)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="0"
            min="-12"
            max="12"
            formControlName="hipOffset"
          />
          <div class="form-error">
            <span *ngIf="hipOffset.invalid && (hipOffset.touched || hipOffset.dirty)"
              >Value must be between -12 and 12</span
            >
          </div>
        </div>
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Valley Offset (in inches)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="0"
            min="-12"
            max="12"
            formControlName="valleyOffset"
          />
          <div class="form-error">
            <span *ngIf="valleyOffset.invalid && (valleyOffset.touched || valleyOffset.dirty)"
              >Value must be between -12 and 12</span
            >
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Eave Offset (in inches)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="0"
            min="-12"
            max="12"
            formControlName="eaveOffset"
          />
          <div class="form-error">
            <span *ngIf="eaveOffset.invalid && (eaveOffset.touched || eaveOffset.dirty)"
              >Value must be between -12 and 12</span
            >
          </div>
        </div>
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Max Length (in feet)<sup>*</sup></div>
          <input
            pInputText
            (blur)="setDefaultMaxLength()"
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="200"
            min="1"
            max="200"
            formControlName="maxLength"
          />
          <div class="form-error">
            <span *ngIf="maxLength.invalid && (maxLength.touched || maxLength.dirty)"
              >Value must be between 1 and 200</span
            >
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Overlap (in inches)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            min="0"
            max="24"
            formControlName="overlap"
          />
          <div class="form-error">
            <span *ngIf="overlap.invalid && (overlap.touched || overlap.dirty)">Value must be between 0 and 24</span>
          </div>
        </div>
        <div fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Panel Type<sup>*</sup></div>
          <select formControlName="panelType" (selectionChange)="submitButtonDisabled = true">
            <option value="">Please select panel type.</option>
            <option *ngFor="let panelType of panelTypes" value="{{ panelType.name }}">
              {{ panelType.displayName }}
            </option>
          </select>
          <div class="form-error">
            <span *ngIf="panelType.invalid && (panelType.touched || panelType.dirty)">Panel Type is required</span>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div *ngIf="panelType.value === PanelTypesEnum.CFS" fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Fastener Spacing (IOC)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="12"
            min="1"
            max="120"
            formControlName="fastenersCfs"
          />
          <div class="form-error">
            <span *ngIf="fastenersCfs.invalid && (fastenersCfs.touched || fastenersCfs.dirty)"
              >Value must be between 1 and 120</span
            >
          </div>
        </div>
        <div *ngIf="panelType.value === PanelTypesEnum.XF" fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Fastener Qty (per SQ)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="85"
            min="1"
            max="300"
            formControlName="fastenersXf"
          />
          <div class="form-error">
            <span *ngIf="fastenersXf.invalid && (fastenersXf.touched || fastenersXf.dirty)"
              >Value must be between 1 and 300</span
            >
          </div>
        </div>
        <div *ngIf="panelType.value === PanelTypesEnum.CFC" fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Clip Spacing (IOC)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="24"
            min="1"
            max="120"
            formControlName="clipSpacingCfc"
          />
          <div class="form-error">
            <span *ngIf="clipSpacingCfc.invalid && (clipSpacingCfc.touched || clipSpacingCfc.dirty)"
              >Value must be between 1 and 120</span
            >
          </div>
        </div>
        <div *ngIf="panelType.value === PanelTypesEnum.CFC" fxFlex="1 1 50%" class="form-field">
          <div class="form-label">Fasteners (per clip)<sup>*</sup></div>
          <input
            pInputText
            class="p-inputtext-sm width-100-pct"
            type="number"
            value="1"
            min="1"
            max="5"
            formControlName="fastenersCfc"
          />
          <div class="form-error">
            <span *ngIf="fastenersCfc.invalid && (fastenersCfc.touched || fastenersCfc.dirty)"
              >Value must be between 1 and 5</span
            >
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="0 0 auto" class="form-field">
      <div class="form-label">Comments</div>
      <textarea pInputTextarea type="text" formControlName="comments" placeholder=""></textarea>
    </div>
    <small *ngIf="errorMessage" id="username-help" class="p-error">{{ errorMessage }}</small>
  </div>
  <div fxFlex="1 1 30%" fxLayout="column">
    <div fxFlex="0 0 auto" class="file-upload-label">Upload Project Documents</div>
    <p-fileUpload
      #wrxFileUpload
      (onSelect)="onFileSelected($event)"
      (onClear)="clearFiles()"
      (onRemove)="onRemoveFile($event)"
      accept=".jpg,.jpeg,.png,.pdf,.xml,.cad,.docx"
      [multiple]="true"
      maxFileSize="200000000"
      [showUploadButton]="false"
    >
    </p-fileUpload>
    <p-dialog
      [header]="dialogData?.header"
      [(visible)]="showJobErrorDialog"
      [modal]="true"
      [style]="{ width: '50vw' }"
      [draggable]="false"
      [resizable]="false"
    >
      <p class="m-0">
        {{ dialogData?.message }}
      </p>
    </p-dialog>
    <div class="mt-4" fxFlex="0 0 auto" fxLayoutAlign="end center">
      <button
        pButton
        pRipple
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text p-button-secondary"
        [class.elevated]="!roofingWRXJobsEnabled"
        (click)="close()"
      ></button>
      <p-button
        type="submit"
        label="Create Job"
        class="p-button-secondary"
        type="submit"
        [disabled]="submitButtonDisabled || !roofingWRXJobsEnabled || !newJobForm.valid || files.length == 0"
        (onClick)="createJob()"
        styleClass="ml-2"
      ></p-button>
    </div>
  </div>
</div>
