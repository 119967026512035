<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right"></p-toast>
<div class="job-form" fxLayout="row" fxLayoutGap="20px">
  <div fxFlex="1 0 30%" class="job-form-info">
    <p>
      <img class="project-icon" src="assets/img/logo_eagleview.png" width="25%" height="25%" />
    </p>
    <br />
    <p class="job-form-title mb-2">
      Company Name:
      <span class="manufacturer-name">{{ (profile$ | async)?.manufacturer.name }}</span>
    </p>
    <p class="job-form-title mb-2">
      Job Name: <span class="manufacturer-name">{{ data.name }}</span>
    </p>
    <p class="job-form-title mb-2">
      Job Id: <span class="manufacturer-name">{{ data.jobId }}</span>
    </p>
    <p class="job-form-title mb-2">
      Modified Date:
      <span class="manufacturer-name">{{ data.modifiedDate | date: "MM/dd/yyyy" }}</span>
    </p>
    <p class="job-form-title mb-2">
      Status: <span class="manufacturer-name">{{ data.status }}</span>
    </p>
    <p class="job-form-title mb-2">
      Address: <span class="manufacturer-name">{{ data.streetAddress }}</span>
    </p>
  </div>

  <div fxFlex="1 1 70%" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
    <p-panel style="height: 100%">
      <ng-template pTemplate="body">
        <div fxFlex="0 0 100%" class="form-field">
          <div fxLayoutAlign="end centebynr">
            <p-button
              *ngIf="data?.canOpen"
              type="button"
              (click)="openJobInSB()"
              label="Open Job in Smartbuild"
              styleClass="p-button-raised p-button-text p-button-primary"
            ></p-button
            >&nbsp;
            <p-button
              type="button"
              (click)="close()"
              label="Close"
              styleClass="p-button-raised p-button-text p-button-danger"
            ></p-button
            >&nbsp;
          </div>
          <p-divider></p-divider>
          <p-tabView>
            <p-tabPanel header="Download">
              <p-panel header="Files">
                <p-panel header="SmartBuild">
                  <div *ngIf="sbFiles?.length > 0">
                    <p-table [value]="sbFiles">
                      <ng-template pTemplate="header">
                        <tr>
                          <th>File Name</th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-file>
                        <tr>
                          <td>{{ file.label }}</td>
                          <td>
                            <button
                              pButton
                              pRipple
                              icon="pi pi-download "
                              class="p-button-sm p-button-text p-button-primary mr-2"
                              (click)="downloadSmartBuildReportFile()"
                            ></button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                  <div *ngIf="sbFiles?.length === 0 || !sbFiles">
                    No SmartBuild files available while job is still in pending status.
                  </div>
                </p-panel>
                <br />
                <br />
                <p-panel header="EagleView">
                  <div *ngIf="evFiles?.length > 0">
                    <p-table [value]="evFiles">
                      <ng-template pTemplate="header">
                        <tr>
                          <th>File Name</th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-file>
                        <tr>
                          <td>{{ file.label }}</td>
                          <td>
                            <button
                              pButton
                              pRipple
                              icon="pi pi-download "
                              class="p-button-sm p-button-text p-button-primary mr-2"
                              (click)="downloadFile(file.value)"
                            ></button>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                  <div *ngIf="evFiles?.length === 0 || !evFiles">
                    No EagleView files available while job is still in pending status.
                  </div>
                </p-panel>
              </p-panel>
            </p-tabPanel>
          </p-tabView>
          <br />
        </div>
      </ng-template>
      <ng-template pTemplate="footer"> </ng-template>
    </p-panel>
  </div>
</div>
