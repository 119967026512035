<div class="container project-list">
  <app-banner></app-banner>
  <p-button *ngIf="isAdmin" class="admin-toggle" icon="pi pi-cog" (onClick)="onAdminPanelOpen()"></p-button>

  <p-panel>
    <ng-template pTemplate="header">
      <h1 role="heading">
        <span class="manufacturer-name" *ngIf="this.profile">Job List - {{ this.profile.manufacturer.name }}</span>
      </h1>
      <br />
    </ng-template>
    <ng-template pTemplate="icons">
      <p-button aria-label="New Job" class="open-dialog" (click)="newJob()">New Job</p-button>
    </ng-template>
    <ng-template pTemplate="body">
      <div class="filter-container">
        <div class="results-container">
          <div class="gridContainer" [hidden]="activeProjects.length === 0">
            <p-tabView #tabView>
              <p-tabPanel header="Active Jobs">
                <p-table
                  *ngIf="activeProjects.length > 0"
                  #dt
                  styleClass="p-datatable-sm p-datatable-striped"
                  responsiveLayout="stack"
                  [value]="activeProjects"
                  [rows]="50"
                  [paginator]="true"
                  [tableStyle]="{ 'min-width': '85rem' }"
                  [(selection)]="selectedProject"
                  [rowHover]="true"
                  dataKey="id"
                  [scrollable]="true"
                  scrollHeight="750px"
                  [style]="{ width: '100%', overflow: 'auto!important' }"
                  [resizableColumns]="true"
                  appendTo="body"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 8%">Source</th>
                      <th style="width: 10%; text-align: center" pSortableColumn="jobId" (click)="showClear()">
                        ID<p-sortIcon field="jobId"></p-sortIcon>
                      </th>
                      <th pSortableColumn="name" style="width: 14%; text-align: center" (click)="showClear()">
                        Job Name <p-sortIcon field="name"></p-sortIcon>
                      </th>
                      <th style="width: 14%; text-align: center" pSortableColumn="streetAddress" (click)="showClear()">
                        Address
                        <p-sortIcon field="streetAddress"></p-sortIcon>
                      </th>
                      <th style="width: 10%" pSortableColumn="modifiedDate" (click)="showClear()">
                        Last Modified<p-sortIcon field="modifiedDate"></p-sortIcon>
                      </th>
                      <th style="width: 15%; text-align: center" pSortableColumn="status" (click)="showClear()">
                        Status<p-sortIcon field="status"></p-sortIcon>
                      </th>
                      <th style="text-align: center">Notes</th>
                      <th style="width: 8%; text-align: center"></th>
                    </tr>
                    <tr>
                      <th style="width: 8%"></th>
                      <th style="width: 10%; text-align: center">
                        <input
                          style="width: 80%"
                          pInputText
                          type="text"
                          (input)="dt.filter($event.target.value, 'jobId', 'contains')"
                          [value]="dt.filters['jobId']?.value"
                          [(ngModel)]="jobIdFilterValue"
                          placeHolder="Search by ID"
                          class="w-full"
                          (keyup)="showClear()"
                        />
                      </th>
                      <th style="width: 14%; text-align: center">
                        <input
                          pInputText
                          type="text"
                          (input)="dt.filter($event.target.value, 'name', 'contains')"
                          [value]="dt.filters['name']?.value"
                          [(ngModel)]="jobNameFilterValue"
                          placeHolder="Search by Name"
                          class="w-full"
                          (keyup)="showClear()"
                        />
                      </th>
                      <th style="width: 14%; text-align: center">
                        <input
                          pInputText
                          type="text"
                          (input)="dt.filter($event.target.value, 'streetAddress', 'contains')"
                          [value]="dt.filters['streetAddress']?.value"
                          [(ngModel)]="addressFilterValue"
                          placeHolder="Search by Address"
                          class="w-full"
                          (keyup)="showClear()"
                        />
                      </th>
                      <th style="width: 10%"></th>
                      <th style="width: 8%"></th>
                      <th></th>
                      <th style="width: 8%"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-project>
                    <tr (click)="getJobInfo(project)">
                      <td style="width: 8%">
                        <img
                          alt="RoofingWRX"
                          *ngIf="project.externalSource === ExternalSource.RoofingWRX"
                          class="project-icon"
                          src="assets/img/logo_roofingwrx.png"
                          width="40%"
                        />
                        <img
                          alt="XML"
                          *ngIf="project.externalSource === ExternalSource.XMLFile"
                          class="project-icon"
                          src="assets/img/logo_xml.png"
                          width="30%"
                        />
                        <img
                          alt="EagleView"
                          *ngIf="project.externalSource === ExternalSource.EagleView"
                          class="project-icon"
                          src="assets/img/logo_eagleview.png"
                          width="40%"
                        />
                      </td>
                      <td style="width: 10%; text-align: center">
                        {{ project.jobId }}
                      </td>
                      <td style="width: 14%; text-align: center">
                        <div>{{ project.name }}</div>
                      </td>
                      <td style="width: 14%; text-align: center">
                        <div class="projectAddress">
                          {{ project.streetAddress }}
                        </div>
                      </td>
                      <td style="width: 10%; text-align: center">
                        {{ project.modifiedDate | date: "MM/dd/yyyy" }}
                      </td>
                      <td style="width: 15%; text-align: center">
                        {{
                          ((!project.customData?.xmlSource && project.externalSource == ExternalSource.RoofingWRX) ||
                            project.customerData?.xmlSource === XmlSource.RoofingWRX) &&
                          project.customData?.roofingWRXStatus
                            ? project.customData?.roofingWRXStatus
                            : project.status
                        }}
                      </td>
                      <td style="text-align: center">
                        {{
                          ((!project.customData?.xmlSource && project.externalSource == ExternalSource.RoofingWRX) ||
                            project.customerData?.xmlSource === XmlSource.RoofingWRX) &&
                          project.customData
                            ? project.customData?.roofingWRXStatusMessage
                            : project.jobNotes
                        }}
                      </td>
                      <td style="width: 8%; text-align: center">
                        <div *ngIf="project.actionItems.length > 0">
                          <button
                            pButton
                            pRipple
                            icon="pi pi-ellipsis-v"
                            class="p-button-sm p-button-text p-button-primary mr-2"
                            (click)="setSelectedProject($event, project); menu.toggle($event)"
                          ></button>
                          <p-tieredMenu
                            #menu
                            [model]="project.actionItems"
                            [popup]="true"
                            appendTo="body"
                          ></p-tieredMenu>
                        </div>
                        <div *ngIf="project.actionItems.length === 0">
                          <button
                            pButton
                            pRipple
                            class="p-button-sm p-button-text p-button-primary mr-2"
                            disabled="true"
                          ></button>
                        </div>
                      </td>
                    </tr>
                    <p-dialog
                      (onClose)="selectedProject = null"
                      [(visible)]="viewJobDetailsDialog"
                      [style]="{ width: '60vw' }"
                      header="Job Details"
                      [modal]="true"
                      styleClass="p-fluid"
                    >
                      <p-toolbar styleClass="mb-4 gap-2">
                        <ng-template pTemplate="right">
                          <p-tabMenu [model]="items"></p-tabMenu>
                        </ng-template>
                      </p-toolbar>
                      <ng-template pTemplate="body">
                        <div class="card">
                          <div class="formgrid grid">
                            <div class="field col-12 md:col-6">
                              <label><b>Job Number:</b> {{ selectedWRXJobInfo.number }}</label>
                            </div>
                            <div class="field col-12 md:col-6">
                              <label><b>Job Name:</b> {{ selectedWRXJobInfo.name }}</label>
                            </div>
                            <div class="field col-12 md:col-6">
                              <label
                                ><b>Request Date:</b> {{ selectedWRXJobInfo.request_date | date: "MM/dd/yyyy" }}</label
                              >
                            </div>
                            <div class="field col-12 md:col-6">
                              <label><b>Status:</b> {{ selectedWRXJobInfo.status }}</label>
                            </div>
                            <div class="field col-12 md:col-6">
                              <label
                                ><b>Status Date:</b> {{ selectedWRXJobInfo.status_date | date: "MM/dd/yyyy" }}</label
                              >
                            </div>
                            <div class="field col-12 md:col-6">
                              <label><b>Employee:</b> {{ selectedWRXJobInfo.employee }}</label>
                            </div>
                          </div>
                        </div>
                      </ng-template>

                      <ng-template pTemplate="footer">
                        <button
                          pButton
                          pRipple
                          label="Close"
                          icon="pi pi-times"
                          class="p-button-text"
                          (click)="hideDialog()"
                        ></button>
                      </ng-template>
                    </p-dialog>
                  </ng-template>
                </p-table>
              </p-tabPanel>
              <p-tabPanel header="Archived Jobs">
                <p-table
                  *ngIf="archivedProjects.length > 0"
                  [columns]="archivedColumns"
                  [value]="archivedProjects"
                  [scrollable]="true"
                  scrollHeight="250px"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="46"
                >
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns" style="width: 20%">
                        {{ col.header }}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns">
                        {{ rowData[col.field] }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <p-panel> You currently do not have any archived jobs. </p-panel>
              </p-tabPanel>
            </p-tabView>
          </div>
          <div class="gridContainer no-grid-data" *ngIf="activeProjects.length === 0">
            You currently do not have any jobs. Create a
            <a href="javascript:void()" (click)="newJob()">New Job</a>
          </div>
        </div>
      </div>
    </ng-template>
  </p-panel>
</div>
