import { Component, EventEmitter, OnInit, Output, ViewChild, Inject, LOCALE_ID } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ColumnApi, GridApi } from "ag-grid-community";
import { BehaviorSubject, Observable, ReplaySubject, Subscription, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { takeUntil } from "rxjs/operators";
import { Manufacturer } from "src/app/core/data/models/AppInitializationData";
import { ManufacturerInvitation } from "src/app/core/data/models/ManufacturerInvitation";
import { CredentialParams } from "src/app/core/data/models/CredentialParams";
import { AdminTrainingStep, TrainingStep } from "src/app/core/data/models/PartnerRegistration";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService, SortEvent } from "primeng/api";
import * as moment from "moment";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { Table } from "primeng/table";
import { ConfirmService } from "src/app/core/confirm/confirm.service";
import { AdminService } from "src/app/shared/services/admin.service";
import { AppState } from "src/app/shared/services/app-state";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
  providers: [MessageService],
})
export class AdminUsersComponent implements OnInit {
  trainingSteps = [
    { label: "- Not Started -", value: TrainingStep.NotStarted },
    {
      label: "A - RP Onboarding Invited",
      value: TrainingStep.RPOnboardInviteSent,
    },
    {
      label: "B - RP Onboarding Scheduled",
      value: TrainingStep.RPOnboardScheduled,
    },
    {
      label: "C - RP Onboarding Initiated",
      value: TrainingStep.RPOnboardInitiated,
    },
    {
      label: "D - RP Onboarding Complete",
      value: TrainingStep.RPOnboardComplete,
    },
  ];

  adminTrainingSteps = [
    { label: "- Not Started -", value: AdminTrainingStep.NotStarted },
    { label: "1 - Upload Materials", value: AdminTrainingStep.UploadMaterials },
    {
      label: "2 - Configure Assemblies",
      value: AdminTrainingStep.ConfigureAssemblies,
    },
    {
      label: "3 - Configure Outputs",
      value: AdminTrainingStep.ConfigureOutputs,
    },
    { label: "4 - Verify TakeOffs", value: AdminTrainingStep.VerifyTakeOffs },
    {
      label: "5 - Admin Training Complete",
      value: AdminTrainingStep.AdminTrainingComplete,
    },
  ];
  @ViewChild("clearContainer") clearContainer: HTMLElement;
  @Output() onCancel = new EventEmitter();
  durationInSeconds = 3;
  userForm: FormGroup;
  busy: Subscription;
  exportErrorMessage: string;
  errorMessage: string;
  selectedUsers: any[] = [];
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  isAdmin = this.appState.isAdmin;
  profile$ = this.appState.profile$;
  multiSortKey = "ctrl";
  activeManufacturers = [];
  adminManufacturerUsers: any[] = [];
  selectedRows: any = [];
  credentialParams: CredentialParams[] = [];
  dataReady = false;
  dataError = false;
  dataErrorMessage;
  invitationEmail;
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private destroyed$ = new ReplaySubject(1);
  public rowSelection: "single" | "multiple" = "multiple";
  viewUserDetailsDialog: boolean;
  editUserDialog: boolean;
  selectedUser: any;
  nonWRXUser: Observable<boolean> = new BehaviorSubject<boolean>(false);
  private _nonWRXUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public enableWRX: Observable<boolean> = this._nonWRXUser.asObservable();
  private _validUserForNewInvitation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public resendButton: Observable<boolean> = this._validUserForNewInvitation.asObservable();
  fullNameFilterValue: string;
  companyNameFilterValue: string;
  private _isClearVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isClearVisible: Observable<boolean> = this._isClearVisible.asObservable();
  private _manufacturers: BehaviorSubject<Manufacturer[]> = new BehaviorSubject<Manufacturer[]>(
    this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
  );
  public manufacturers: Observable<Manufacturer[]> = this._manufacturers.asObservable();
  public companyId: number = this.appState.currentProfile.manufacturer.id;

  constructor(
    private appState: AppState,
    private adminService: AdminService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private profileService: ProfileService,
    public spinner: SpinnerService,
    private confirmService: ConfirmService,
    private messageService: MessageService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    this.initializeForm();

    this.appState.profile$.pipe(takeUntil(this.destroyed$)).subscribe((profile) => {
      this._manufacturers.next(
        this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
      );
      this.companyId = this.appState.currentProfile.manufacturer.id;
      this.getManufacturerUsers(this.appState.currentProfile.manufacturer.id);
      this.patchForm();
    });
  }

  initializeForm() {
    this.setupForm();
    this.getManufacturerUsers(this.companyId);
  }

  setupForm() {
    this.userForm = this.fb.group({
      companyId: this.companyId,
      userId: 0,
      email: "",
      trainingStatusId: null,
      adminTrainingStatusId: null,
      adminTrainingStatus: "",
      trainingStatus: "",
      firstName: "",
      lastName: "",
      companyName: "",
      companyAddress: "",
      phoneNumber: "",
      role: "",
      fullNameFilterValue: "",
      companyNameFilterValue: "",
    });
  }

  patchForm(user: any = null) {
    if (user === null) {
      this.userForm.patchValue({
        ...this.userForm.value,
        companyId: this.companyId,
      });
    } else {
      this.userForm.patchValue({
        companyId: this.companyId,
        userId: user.id ? this.selectedUser.id : 0,
        email: user.email ? user.email : "",
        trainingStatusId: user.trainingStatusId ? user.trainingStatusId : 0,
        adminTrainingStatusId: user.adminTrainingStatusId ? user.adminTrainingStatusId : 0,
        trainingStatus: user.trainingStatus ? user.trainingStatus : "",
        adminTrainingStatus: user.adminTrainingStatus ? user.adminTrainingStatus : "",
        firstName: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
        companyName: user.companyName ? user.companyName : "",
        companyAddress: user.companyAddress ? user.companyAddress : "N/A",
        phoneNumber: user.phoneNumber ? user.phoneNumber : "N/A",
        role: user.role ? user.role : "",
      });
    }

    this.viewUserDetailsDialog = user !== null;
  }

  exportUsers() {
    this.exportErrorMessage = null;
    this.busy = this.adminService
      .downloadUserReport(this.companyId)
      .pipe(
        (catchError((error: HttpErrorResponse) => {
          this.exportErrorMessage = error.error ?? AppConstants.RoofingPassportCommunicationError;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.exportErrorMessage,
          });
          return of(null);
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Report has been downloaded.",
          });
        }))
      )
      .subscribe();
  }

  resendRPInvitation() {
    const invite: ManufacturerInvitation = {
      companyId: this.companyId,
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      companyName: this.selectedUser.companyName,
      contactPhoneNumber: this.selectedUser.contactPhoneNumber,
      email: this.selectedUser.email,
    };

    this.profileService
      .resendRPInvitation(invite)
      .pipe(
        (catchError((error: HttpErrorResponse) => {
          this.errorMessage = error.message;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.errorMessage,
          });
          return of(null);
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invitation emails have been succesfully sent.",
          });
          this.getManufacturerUsers(this.companyId);
          this.hideDialog();
          return of(null);
        }))
      )
      .subscribe();
  }

  viewUserDetails(user: any) {
    //Make sure selected users is cleared out upon dialog opening.
    this.selectedUsers = [];
    this.validateNonWrxUser(user);
    this.validateInvitationStatus(user);

    this.selectedUser = { ...user };
    this.selectedUsers.push(this.selectedUser);
    this.patchForm(user);
  }

  hideDialog() {
    this.clearSelectedUser();
    this.viewUserDetailsDialog = false;
  }

  clearSelectedUser() {
    this.selectedUsers = [];
    this.selectedUser = null;
  }

  saveUser() {
    console.log("User Saved!");
  }

  validateInvitationStatus(user: any) {
    const allRegistrationCompleted =
      (user.roofingWRXRegistrationCompleted !== null) &&
      user.smartBuildRegistrationCompleted !== null &&
      user.eagleViewRegistrationCompleted !== null;

    const nonWrxUserRegistrationCompleted =
      !user.roofingWRXEnabled &&
      !user.roofingWRXJobsEnabled &&
      user.smartBuildRegistrationCompleted !== null &&
      user.eagleViewRegistrationCompleted !== null;

    const registrationCompleted =
      user.invitationStatus === "Accepted" && (allRegistrationCompleted || nonWrxUserRegistrationCompleted);

    this._validUserForNewInvitation.next(registrationCompleted);
  }

  validateNonWrxUser(user: any) {
    const manufacturer = this._manufacturers.value.find((m) => m.id === user.manufacturerId);

    const isManufacturerWrxEnabled = manufacturer?.roofingWRXEnabled && manufacturer?.roofingWRXJobsEnabled;

    // user?.roofingWRXEnabled && user?.roofingWRXJobsEnabled must be false, not null
    const scenario1 =
      isManufacturerWrxEnabled && user?.roofingWRXEnabled === false && user?.roofingWRXJobsEnabled === false;

    // user?.roofingWRXEnabled && user?.roofingWRXJobsEnabled can be null or false
    const scenario2 = !isManufacturerWrxEnabled && !user?.roofingWRXEnabled && !user?.roofingWRXJobsEnabled;

    this._nonWRXUser.next(scenario1 || scenario2);
  }

  onPageSizeChanged(value: string): void {
    this.gridApi.paginationSetPageSize(Number(value));
    this.gridApi.paginationGoToPage(0);
    this.localStorageService.getLocalStorage().setItem("gridPageSize", value);
  }

  getManufacturerUsers(companyId: number) {
    this.adminService
      .getUsersByManufacturerId(companyId)
      .pipe(
        tap((response: any) => {
          if (response) {
            this.adminManufacturerUsers = response.filter((x) => x.status !== "Inactive");
          } else {
            this.adminManufacturerUsers = [];
          }
        })
      )
      .subscribe();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === "string" && typeof value2 === "string") {
        if (event.field === "rpLastConnected") {
          const date1 = moment(value1, "MM/DD/YYYY");
          const date2 = moment(value2, "MM/DD/YYYY");

          let result: number = -1;
          if (moment(date2).isBefore(date1, "day")) {
            result = 1;
          }

          return result * event.order;
        }
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }
      return event.order * result;
    });
  }

  onSortChanged(): void {
    const sortModel = this.gridColumnApi.getColumnState();
    this.localStorageService.getLocalStorage().setItem("sortSetting", JSON.stringify(sortModel));
  }

  dateComparator(date1, date2) {
    const date1Number = date1 && new Date(date1).getTime();
    const date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  onManufacturerSelectionChanged(selectedCompanyId) {
    this.companyId = selectedCompanyId.value;
    this.getManufacturerUsers(selectedCompanyId.value);
  }

  addRoofingWRX() {
    const data = {
      userId: this.selectedUser.id,
      manufacturerId: this.companyId,
      email: this.selectedUser.email,
    };
    const userToSave = [];
    userToSave.push(data);

    this.confirmService.confirm(
      "Warning",
      "Are you sure you want to enable RoofingWRX for " + this.selectedUser?.fullName + "?",
      "pi pi-question-circle",
      () => {
        this.profileService
          .updateRoofingWRXEnabled(userToSave)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.errorMessage = error.message;
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: this.errorMessage,
              });
              return of(null);
            }),
            tap(() => {
              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "RoofingWRX has been successfully updated for selected user.",
              });
              this.getManufacturerUsers(this.companyId);
              this.hideDialog();
            })
          )
          .subscribe();
      }
    );
  }

  deactivateUser() {
    const data = {
      userId: this.selectedUser.id,
      manufacturerId: this.companyId,
      email: this.selectedUser.email,
    };
    const userToSave = [];
    userToSave.push(data);

    this.confirmService.confirm(
      "Warning",
      "Are you sure you want to make selected user inactive?",
      "pi pi-question-circle",
      () => {
        this.profileService
          .deactivateUser(userToSave)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.errorMessage = error.message;
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: this.errorMessage,
              });
              return of(null);
            }),
            tap(() => {
              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "User has been made inactive.",
              });
              this.getManufacturerUsers(this.companyId);
              this.hideDialog();
            })
          )
          .subscribe();
      }
    );
  }

  showClear(table: Table) {
    const isDirty =
      this.userForm.get("fullNameFilterValue").value ||
      this.userForm.get("companyNameFilterValue").value ||
      table._sortField;

    if (isDirty && !this._isClearVisible.value) {
      this._isClearVisible.next(true);
    }

    if (!isDirty && this._isClearVisible.value) {
      this._isClearVisible.next(false);
    }
  }

  clear(table: Table) {
    table.filters = {};

    // Reset to the default order
    table.sortField = "companyName";
    table.sortOrder = 1;
    table.sortSingle();

    table.reset();

    // Remove the sort icon
    table.sortOrder = 0;
    table.sortField = "";

    this.userForm.get("fullNameFilterValue")?.setValue("");
    this.userForm.get("companyNameFilterValue")?.setValue("");

    this._isClearVisible.next(false);
  }

  saveUserTrainingStatus() {
    const trainingStatusObject = {
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      email: this.selectedUser.email,
      trainingStatus: this.userForm?.controls.trainingStatusId?.value,
      adminTrainingStatus: this.userForm?.controls.adminTrainingStatusId?.value,
      manufacturerId: this.companyId,
    };

    this.profileService
      .updateTrainingStatus(trainingStatusObject)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.errorMessage = err.message;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: this.errorMessage,
          });
          return of(null);
        }),
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "User changes have been successfully saved for " + this.selectedUser.fullName + ".",
          });
          this.getManufacturerUsers(this.companyId);
          this.hideDialog();
        })
      )
      .subscribe();
  }
}
