import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription, timer } from "rxjs";
import { ExternalSource, SmartBuildProject, XmlSource } from "../../../core/data/models/SmartBuildProject";
import { ColumnApi, GridApi } from "ag-grid-community";
import { catchError, map, takeUntil, finalize, skipWhile, switchMap, take } from "rxjs/operators";
import { WindowRefService } from "src/app/shared/helpers/window-ref.service";
import { NewJobDialogComponent } from "../new-job-dialog/new-job-dialog.component";
import { ManufacturerProfile } from "src/app/core/data/models/AppInitializationData";
import { RoofingWrxJobDetailsComponent } from "../details/roofing-wrx-job-details/roofing-wrx-job-details.component";
import { Table } from "primeng/table";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { EagleViewJobDetailsComponent } from "../details/eagle-view-job-details/eagle-view-job-details.component";
import { XMLJobDetailsComponent } from "../details/xml-job-details/xml-job-details.component";
import { IGridColumn } from "src/app/shared/grid/base-grid";
import { TabView } from "primeng/tabview";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppState } from "src/app/shared/services/app-state";
import { SmartBuildService } from "src/app/shared/services/smartbuild.service";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { OrderService } from "src/app/shared/services/order.service";
import { AuthContext } from "src/app/shared/services/authentication.service";

@Component({
  selector: "app-project-list",
  templateUrl: "./project-list.component.html",
  styleUrls: ["./project-list.component.scss"],
  providers: [DialogService],
})
/** ProjectList component*/
export class ProjectListComponent implements OnInit, OnDestroy, AfterViewInit {
  ref: DynamicDialogRef | undefined;
  ExternalSource = ExternalSource;
  XmlSource = XmlSource;
  @ViewChild("dt") table: Table;
  @ViewChild("tabView") tabView: TabView;
  isAdmin = false;
  uploadedFiles: any[] = [];
  jobIdFilterValue: string;
  jobNameFilterValue: string;
  addressFilterValue: string;
  profile: ManufacturerProfile;
  projectList: SmartBuildProject[] = [];
  activeProjects: any = [];
  archivedProjects = [];
  archivedJobsRetrieved = false;
  dataReady = false;
  dataError = false;
  dataErrorMessage;
  isRoofingWRX: boolean;
  isEagleView: boolean;
  isXml: boolean;
  showProjectLink: boolean;
  projectLogo: string;
  selectedProject: any;
  selectedWRXJobInfo: any;
  archiveGridNoRowsOverlayTemplate = "<span class='ag-overlay-no-rows-center'>No Archived Jobs</span>";
  archiveGridLoadingOVerlay = '<span class="ag-overlay-loading-center">Retrieving archived jobs</span>';
  smartBuildJobEditUrlBase = `${this.appState.configs.SmartBuildPath}Framer/EditWithToken/`;
  gridPageSize = 10;
  viewJobDetailsDialog = false;
  private _removeClearListener: () => void;
  private _removeRefreshListener: () => void;
  private _isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRefreshing: Observable<boolean> = this._isRefreshing.asObservable();
  private isClearVisible = false;
  private clearContainer: HTMLElement;

  archivedColumns: IGridColumn[] = [
    {
      field: "jobId",
      header: "ID",
      type: "string",
    },
    {
      field: "name",
      header: "Job Name",
      type: "string",
    },
    {
      field: "streetAddress",
      header: "Address",
      type: "string",
    },
    {
      field: "modifiedDate",
      header: "Last Modified",
      type: "date",
    },
    {
      field: "status",
      header: "Status",
      type: "string",
    },
    {
      field: "jobNotes",
      header: "Notes",
      type: "string",
    },
  ];

  multiSortKey = "ctrl";
  busy: Subscription;

  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  private archivedGridApi: GridApi;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private refreshTimer: Subscription;

  /** ProjectList ctor */
  constructor(
    private router: Router,
    public appState: AppState,
    private smartbuildService: SmartBuildService,
    private localStorageService: LocalStorageService,
    private windowRef: WindowRefService,
    private orderService: OrderService,
    private spinner: SpinnerService,
    private renderer: Renderer2,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.appState.appData$
      .pipe(
        skipWhile((d) => d == null),
        takeUntil(this.$destroyed)
      )
      .subscribe();

    const profile$ = this.appState.profile$.pipe(
      skipWhile((p) => p == null),
      takeUntil(this.$destroyed)
    );

    profile$.subscribe((profile) => {
      let profileChanged = this.profile && this.profile.manufacturer.id !== profile?.manufacturer.id;
      this.isAdmin = this.appState.isAdmin;
      this.profile = profile;
      if (profileChanged) {
        this.refreshJobList(true);
      }
    });

    // only show busy until we get a profile once
    this.busy = profile$.pipe(take(1), switchMap(this.onProfileReady.bind(this))).subscribe();
  }

  ngAfterViewInit(): void {
    this.createActions();
  }

  ngOnDestroy() {
    this._removeClearListener();
    this._removeRefreshListener();
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  private onProfileReady(profile: ManufacturerProfile) {
    if (!this.refreshTimer) {
      const interval = this.appState.configs.ProjectListRefreshInterval * 60 * 1000;
      this.refreshTimer = timer(interval, interval)
        .pipe(takeUntil(this.$destroyed))
        .subscribe(this.refreshJobList.bind(this, false));
    }

    this.profile = profile;
    return this.getProjects(false);
  }

  public onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.restoreFromSave();
  }

  public onArchivedGridReady(params): void {
    this.archivedGridApi = params.api;
  }

  newJob() {
    this.ref = this.dialogService.open(NewJobDialogComponent, {
      modal: true,
      width: "1400px",
      height: "750px",
      closable: false,
      showHeader: false,
      contentStyle: { padding: "10px 5px", borderRadius: "6px" },
    });
    this.ref.onClose.subscribe(() => {
      this.refreshJobList(false);
    });
  }

  openJobDetails() {
    const dialogConfig = new DynamicDialogConfig();
    (dialogConfig.modal = true), (dialogConfig.showHeader = false), (dialogConfig.height = "750px");
    dialogConfig.width = "1400px";
    dialogConfig.contentStyle = { padding: "0px" };
    dialogConfig.data = this.isRoofingWRX ? this.selectedWRXJobInfo : this.selectedProject;
    if (this.isRoofingWRX) {
      dialogConfig.data.smartBuildData = this.selectedProject;
      this.ref = this.dialogService.open(RoofingWrxJobDetailsComponent, dialogConfig);
    }
    if (this.isEagleView) {
      this.ref = this.dialogService.open(EagleViewJobDetailsComponent, dialogConfig);
    }
    if (this.isXml) {
      this.ref = this.dialogService.open(XMLJobDetailsComponent, dialogConfig);
    }
    this.ref.onClose.subscribe(() => {
      this.refreshJobList(false);
    });
  }

  setSelectedProject(project: any) {
    this.selectedProject = project;
  }

  refreshJobList(showBusy: boolean = false): void {
    this._isRefreshing.next(true);
    this.getProjects(showBusy);
    if (this.archivedJobsRetrieved) {
      this.getArchivedJobs();
    }
  }

  getProjects(showBusy: boolean) {
    const jobs = this.getJobList();
    const sub = jobs.subscribe(this.processProjectList.bind(this));
    this.busy = showBusy ? sub : null;
    return jobs;
  }

  switchProfile() {
    this.router.navigate(["profile"]);
  }

  viewJobDetails(project: any) {
    //Make sure selected user is cleared out upon dialog opening.
    this.selectedProject = null;

    this.selectedProject = { ...project };
  }

  private getJobList(): Observable<any[]> {
    return this.smartbuildService.getProjects("false").pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // assume auth data is expired, so set our smartbuild auth to expired and navigate to setup
          this.appState.expireAuthData(AuthContext.SmartBuild);
          this.router.navigate(["profile"]);
        }
        this.dataError = true;
        this.dataErrorMessage =
          err.status === 0 ? this.appState.configs.ProjectListCommunicationErrorMessage : err.error;
        return of(null);
      }),
      take(1),
      finalize(() => this.spinner.hide())
    );
  }

  getJobInfo(project: any) {
    this.selectedProject = project;
    const xmlSource = project.customData?.xmlSource;
    const isRoofingWRX =
      (!xmlSource && project.externalSource == ExternalSource.RoofingWRX) || xmlSource == XmlSource.RoofingWRX;
    this.isRoofingWRX = isRoofingWRX;
    const isXml = (!xmlSource && project.externalSource == ExternalSource.XMLFile) || xmlSource == XmlSource.Generic;
    this.isXml = isXml;
    const isEagleView =
      (!xmlSource && project.externalSource == ExternalSource.EagleView) || xmlSource == XmlSource.EagleView;
    this.isEagleView = isEagleView;
    let jobId;

    if (this.selectedProject.externalSource === ExternalSource.RoofingWRX) {
      jobId = this.selectedProject.customData?.roofingWRXJobId || this.selectedProject.externalId;
    } else {
      jobId = this.selectedProject.jobId;
    }
    if (isRoofingWRX && project.customData?.roofingWRXStatus !== "Cancelled") {
      this.orderService.getRoofingWRXJobInfo(jobId).subscribe((info) => {
        this.selectedWRXJobInfo = info ?? [];
        if (info) {
          this.openJobDetails();
        }
      });
    } else {
      this.openJobDetails();
    }
  }

  hideDialog() {
    this.selectedProject = null;
    this.viewJobDetailsDialog = false;
  }

  tabChanged(event: any): void {
    this.getArchivedJobs(true);
    this.archivedJobsRetrieved = true;
  }

  onSortChanged($event): void {
    const sortModel = $event.api.getSortModel();
    this.localStorageService.getLocalStorage().setItem("sortSetting", JSON.stringify(sortModel));
  }

  onPageSizeChanged(value: string): void {
    this.gridApi.paginationSetPageSize(Number(value));
    this.archivedGridApi.paginationSetPageSize(Number(value));
    this.gridApi.paginationGoToPage(0);
    this.archivedGridApi.paginationGoToPage(0);
    this.localStorageService.getLocalStorage().setItem("gridPageSize", value);
  }

  onAdminPanelOpen() {
    this.router.navigate(["admin"]);
  }

  onAdminPanelClose() {
    this.router.navigate(["project-list"]);
  }

  setExternalSource(params: any) {
    let xmlSource: string;
    xmlSource = params.customData?.xmlSource;

    this.isRoofingWRX =
      (!xmlSource && params.externalSource == ExternalSource.RoofingWRX) || xmlSource == XmlSource.RoofingWRX;
    this.isXml = (!xmlSource && params.externalSource == ExternalSource.XMLFile) || xmlSource == XmlSource.Generic;
    this.isEagleView =
      (!xmlSource && params.externalSource == ExternalSource.EagleView) || xmlSource == XmlSource.EagleView;
  }

  private processProjectList(jobs): void {
    this._isRefreshing.next(false);
    if (jobs) {
      this.setProjects(jobs);
      this.dataError = false;
      this.dataReady = true;
    } else {
      this.activeProjects = [];
    }
  }

  private setProjects(projects: any): void {
    this.projectList = projects;
    this.activeProjects = [];
    this.setActionItems(this.projectList);
    this.restoreFromSave();
  }

  setActionItems(projects: any) {
    for (const proj of projects) {
      //Set RoofingWRX Status to Complete if SmartBuild sends status of "canOpen"
      if (proj.externalSource === ExternalSource.RoofingWRX && proj.canOpen) {
        proj.status = "Complete";
      }
      let projectList: any;

      projectList = {
        jobId: proj.jobId,
        name: proj.name,
        streetAddress: proj.streetAddress,
        cityStateZip: proj.cityStateZip,
        status: proj.status,
        createdDate: proj.createdDate,
        modifiedDate: proj.modifiedDate,
        canOpen: proj.canOpen,
        eagleViewAction: proj.eagleViewAction,
        jobNotes: proj.jobNotes,
        externalSource: proj.externalSource,
        externalId: proj.externalId,
        customData: proj.customData,
        actionItems: [],
      };

      this.activeProjects.push(projectList);
    }
  }

  clear() {
    this.table.filters = {};

    this.jobIdFilterValue = "";
    this.jobNameFilterValue = "";
    this.addressFilterValue = "";

    // Reset to the default order
    this.table.sortField = "modifiedDate";
    this.table.sortOrder = -1;
    this.table.sortSingle();
    this.table.reset();

    // Remove the sort icon
    this.table.sortOrder = 0;
    this.table.sortField = "";

    this.setClearVisible(false);
    this.isClearVisible = false;
  }

  showClear() {
    const isDirty =
      this.jobIdFilterValue || this.jobNameFilterValue || this.addressFilterValue || this.table._sortField;

    if (isDirty && !this.isClearVisible) {
      this.setClearVisible(true);
      this.isClearVisible = true;
    }
    if (!isDirty && this.isClearVisible) {
      this.setClearVisible(false);
      this.isClearVisible = false;
    }
  }

  private restoreFromSave(): void {
    const sortModel = JSON.parse(this.localStorageService.getLocalStorage().getItem("sortSetting"));
    if (sortModel && this.gridColumnApi) {
      this.gridColumnApi.applyColumnState({
        state: sortModel,
        defaultState: { sort: null },
      });
    }
    const pageSize = this.localStorageService.getLocalStorage().getItem("gridPageSize");
    if (pageSize) {
      this.gridPageSize = Number(pageSize);
      if (this.gridApi) {
        this.gridApi.paginationSetPageSize(this.gridPageSize);
      }
      if (this.archivedGridApi) {
        this.archivedGridApi.paginationSetPageSize(this.gridPageSize);
      }
    }
  }

  private getArchivedJobs(showBusy: boolean = false): void {
    const archivedJobsObservable = this.smartbuildService.getProjects("true").pipe(
      map((data) => data),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // assume auth data is expired, so set our smartbuild auth to expired and navigate to setup
          this.appState.expireAuthData(AuthContext.SmartBuild);
          this.router.navigate(["profile"]);
        }
        this.dataError = true;
        this.dataErrorMessage =
          err.status === 0 ? this.appState.configs.ProjectListCommunicationErrorMessage : err.error;
        return of(null);
      })
    );
    if (showBusy) {
      this.busy = archivedJobsObservable.subscribe((jobs) => {
        this.archivedProjects = jobs;
      });
    } else {
      archivedJobsObservable.subscribe((jobs) => {
        this.archivedProjects = jobs;
      });
    }
  }

  private createActions() {
    const actions = this.renderer.createElement("div");

    this.renderer.addClass(actions, "actions-container");

    const clearContainer = this.renderer.createElement("div");
    this.renderer.setAttribute(clearContainer, "id", "clearContainer");
    this.renderer.addClass(clearContainer, "clear-container");
    this.renderer.setStyle(clearContainer, "visibility", "hidden");

    this.clearContainer = clearContainer;

    const pButtonClear = this.createPButtonClear();
    const buttonClear = this.createButtonClear();
    const spanClear = this.createClearIcon();

    this.renderer.appendChild(buttonClear, spanClear);
    this.renderer.appendChild(pButtonClear, buttonClear);
    this.renderer.appendChild(clearContainer, pButtonClear);

    const refreshContainer = this.renderer.createElement("div");
    this.renderer.addClass(refreshContainer, "refresh-container");

    const refresh = this.renderer.createElement("div");
    this.renderer.addClass(refresh, "refresh");

    const svg = this.createRefreshSvg();
    const use = this.createRefreshUse();

    this.renderer.appendChild(svg, use);
    this.renderer.appendChild(refresh, svg);
    this.renderer.appendChild(refreshContainer, refresh);

    this.renderer.appendChild(actions, clearContainer);
    this.renderer.appendChild(actions, refreshContainer);

    const nav_header = (this.tabView.content.nativeElement as HTMLElement).firstChild;
    this.renderer.appendChild(nav_header, actions);
  }

  private createPButtonClear() {
    const pButtonClear = this.renderer.createElement("p-button");
    this.renderer.setAttribute(pButtonClear, "styleClass", "p-button-text p-button-info");
    this.renderer.setAttribute(pButtonClear, "pTooltip", "Reset filters");
    this.renderer.setAttribute(pButtonClear, "iconPos", "left");
    this.renderer.setAttribute(pButtonClear, "icon", "pi pi-filter-slash");
    this.renderer.setAttribute(pButtonClear, "pRipple", "");
    return pButtonClear;
  }

  private createButtonClear() {
    const buttonClear = this.renderer.createElement("button");
    this.renderer.setAttribute(buttonClear, "pRipple", "");
    this.renderer.addClass(buttonClear, "p-ripple");
    this.renderer.addClass(buttonClear, "p-element");
    this.renderer.addClass(buttonClear, "p-button-text");
    this.renderer.addClass(buttonClear, "p-button-info");
    this.renderer.addClass(buttonClear, "p-button-icon-only");
    this.renderer.addClass(buttonClear, "p-component");
    this.renderer.addClass(buttonClear, "p-button");
    this._removeClearListener = this.renderer.listen(buttonClear, "click", () => this.clear());
    return buttonClear;
  }

  private createClearIcon() {
    const spanClear = this.renderer.createElement("span");
    this.renderer.addClass(spanClear, "pi");
    this.renderer.addClass(spanClear, "pi-filter-slash");
    this.renderer.addClass(spanClear, "ng-star-inserted");
    this.renderer.addClass(spanClear, "p-button-icon");
    this.renderer.setAttribute(spanClear, "aria-hidden", "true");
    return spanClear;
  }

  private createRefreshSvg() {
    const svg = this.renderer.createElement("svg", "http://www.w3.org/2000/svg");
    this.renderer.setAttribute(svg, "title", "Refresh Project List");
    this.renderer.addClass(svg, "icon-svg");
    this.renderer.addClass(svg, "refresh");
    this._removeRefreshListener = this.renderer.listen(svg, "click", () => this.refreshJobList());

    this._isRefreshing.subscribe((value) => {
      const action = value ? "addClass" : "removeClass";
      this.renderer[action](svg, "loading");
    });
    return svg;
  }

  private createRefreshUse() {
    const use = this.renderer.createElement("use", "http://www.w3.org/2000/svg");
    this.renderer.setAttribute(use, "href", "assets/img/icons.svg#refresh");
    return use;
  }

  private setClearVisible(visible: boolean) {
    this.renderer.setStyle(this.clearContainer, "visibility", visible ? "visible" : "hidden");
  }
}
